@import "../../assets/css-vars.scss";

.wrapper-taskbar {
    position:absolute;
    bottom: 0;
    background-color: var(--gray-main);
    width: 100%;
    height: 1.9em;
    border-top: 2px solid var(--gray-highlight);
    z-index: 90;
    border-bottom-left-radius: 0.1em;
    border-bottom-right-radius: 0.1em;
}

// mobile
@media (max-width: 768px) {
    .wrapper-taskbar {
        z-index: 130;
        border-bottom-left-radius: 0em;
        border-bottom-right-radius: 0em;
    }
}

.taskbar-applications-wrapper {
    position:relative;
    margin-right: 7.2em;
    overflow: hidden;
}

.taskbar-applications-wrapper table {
    // width: 100%;
    margin-right: 7.2em;
}

.startButton {
    height: 1.6em;
    position: relative;
    bottom: 0.2em;
    width: 4.4em;
    line-height: 0.2em;
    display:inline-block;
    border-top: 2px solid var(--gray-highlight);
    border-left: 2px solid var(--gray-highlight);
    border-right: 2px solid var(--gray-dark);
    border-bottom: 2px solid var(--gray-dark);
    margin-left: 0.1em;
}

.startButton:hover {
    border-top: 2px solid var(--gray-dark);
    border-left: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-highlight);
    border-bottom: 2px solid var(--gray-highlight);
    cursor: pointer;
}

.startButton img {
    height: 1.3em;
    width: auto;
    padding-left: 0.1em;
    padding-right: 0.2em;
}

.startButton label {
    font-weight: bold;
    font-size: 0.7em;
    padding-left: 0.3em;
}


.application_placeholder-wrapper {
    height: 1.6em;
    position: relative;
    bottom: 0.1em;
    width: 9.5em;
    display:flex;
    align-items: center;
    border-top: 2px solid var(--gray-highlight);
    border-left: 2px solid var(--gray-highlight);
    border-right: 2px solid var(--gray-dark);
    border-bottom: 2px solid var(--gray-dark);
    cursor: pointer;
}

.application_placeholder-wrapper:hover {
    border-top: 2px solid var(--gray-dark);
    border-left: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-highlight);
    border-bottom: 2px solid var(--gray-highlight);
}

.application_placeholder-wrapper img {
    height: 1.1em;
    width: auto;
    padding-left: 0.1em;
    
}

.application_placeholder-wrapper label {
    font-weight: normal;
    font-size: 0.6em;
    padding-left: 0.3em;
    position: relative;
    top: 0.09em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tb-selected {
    border-top: 2px solid var(--gray-dark);
    border-left: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-highlight);
    border-bottom: 2px solid var(--gray-highlight);
    background-color: var(--gray-dark);
}


.tb-move-button {
    height: 1.5em;
    width: 2em;
    border-bottom: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-dark);
    border-top: 2px solid var(--gray-highlight);
    border-left: 2px solid var(--gray-highlight);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
    background-color: var(--gray-main);
}

.tb-move-button label {
    padding: 0;
    font-size: 0.75em;
    position: relative;
    top: -0.15em;
    color: var(--gray-black);
    opacity: 0.3;
}

.taskbar-moveRight {
    position: absolute;
    bottom: 0.2em;
    right: 5.1em;
}

.taskbar-moveLeft {
    position:relative;
    bottom: 0.1em;
}
