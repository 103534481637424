

.window-wrapper {
    background-color: var(--gray-main);
    border-top: 2px solid var(--gray-highlight);
    border-left: 2px solid var(--gray-highlight);
    border-bottom: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-dark); 
    position:fixed;
    overflow: hidden;
    z-index: 60;
}

.window-header {
    width: 100%;
    height: 1.8em;
    background-color: var(--blue95);
    color: var(--text-white);
    position:absolute;
    font-size: 0.7em;
}

// for mobile
@media only screen and (max-width: 768px) {
    .window-header {
        height: 2.5em;
    }
}

.window-header-listener {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 5em);
}

.window-header img {
    width: 1.5em;
    height: 1.5em;
    margin-left: 0.5em;
}

.window-header-title {
    padding-left: 0.6em;
    padding-top: 0.2em;
    font-size: 0.9em;
}

// for mobile
@media only screen and (max-width: 768px) {
    .window-header-title {
        padding-top: 0.4em;
        position: relative;
        top: 0.2em;
    }


    .window-header-options {
        float:right;
        position:relative;
        left: -0.35em;
        top: -0.1em;
    }
}

.window-header-options {
    float:right;
    position:relative;
    right: 0.2em;
}

.window-header-options button {
    height: 1rem;
    width: 1rem;
    line-height: 1em;
    text-align: center;
    text-indent: -3px;
    font-weight: bold;
    font-size: 0.5rem;
    background-color: var(--gray-main);
    background-color: var(--gray-main);
    border-top: 2px solid var(--gray-main);
    border-left: 2px solid var(--gray-main);
    border-bottom: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-dark); 
    margin-left: 0.5em;
    color: #000;
}

// for mobile
@media only screen and (max-width: 768px) {
    .window-header-options button {
        height: 1.2rem;
        width: 1.2rem;
        font-size: 0.8em !important;
        position:relative;
        top: 0.3em;
    }
}

.window-header-options button:hover { 
    border-top: 2px solid var(--gray-dark);
    border-left: 2px solid var(--gray-dark);
    border-bottom: 2px solid var(--gray-main);
    border-right: 2px solid var(--gray-main); 
}

.window-content {
    position:relative;
    top: 1.5em;
    width: 100%;
    height: calc(100% - 1.8em);
}

// for mobile
@media only screen and (max-width: 768px) {
    .window-content {
        top: 1.85em;
        height: calc(100% - 1.85em);
    }
}

.minimize_button {
    position:relative;
    top: -0.2em;
}

@media only screen and (max-width: 768px) {
    .maximize_button {
        position:relative;
        left: -0.5em;
        top: 0.05em;
        width: 1.3em;
        height: 1.3em;
    }

    .unmaximize_button_wrapper {
        position: absolute;
        width: 1em;
        height: 1em;
        top: 0.6em;
        margin-top: -0.5em;
        margin-left: -0.15em;
        display: block;
    }


    .unmaximize_button_2 {
        position:absolute;
        top: 0.5em;
        left: 0.1em;
    }
}

// .unmaximize_button_wrapper {
//     position: absolute;
//     width: 1em;
//     height: 1em;
//     top: 0.6em;
//     margin-top:0.1em;
//     margin-left: -0.2em;
//     // left: 0;
// }

