

.window-wrapper {
    background-color: var(--gray-main);
    border-top: 2px solid var(--gray-highlight);
    border-left: 2px solid var(--gray-highlight);
    border-bottom: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-dark); 
    position:fixed;
    overflow: hidden;
    z-index: 60;
}

.window-header {
    width: 100%;
    height: 1.8em;
    background-color: var(--blue95);
    color: var(--text-white);
    position:absolute;
    font-size: 0.7em;
}

.window-header-listener {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 5em);
}

.window-header img {
    width: 1.5em;
    height: 1.5em;
    margin: 0.1em;
}

.window-header-title {
    padding-left: 0.6em;
    padding-top: 0.2em;
    font-size: 0.9em;
}

.window-header-options {
    float:right;
    position:relative;
    right: 0.2em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.window-header-options button {
    height: 1rem;
    width: 1rem;
    line-height: 1em;
    text-align: center;
    text-indent: -3px;
    font-weight: bold;
    font-size: 0.5rem;
    background-color: var(--gray-main);
    background-color: var(--gray-main);
    border-top: 2px solid var(--gray-main);
    border-left: 2px solid var(--gray-main);
    border-bottom: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-dark); 
    margin-left: 0.2em;
    margin-top: 0.3em;
}

.window-header-options button:hover { 
    border-top: 2px solid var(--gray-dark);
    border-left: 2px solid var(--gray-dark);
    border-bottom: 2px solid var(--gray-main);
    border-right: 2px solid var(--gray-main); 
}

.window-content {
    position:relative;
    top: 1.5em;
    width: 100%;
    height: calc(100% - 1.8em);
}

.minimize_button {
    position:relative;
    top: -0.2em;
}

.maximize_button {
    position: relative;
    top: -0.1em;
    left: -0.9em;
    border: 1px solid #000;
    border-top: 3px solid #000;
    width: 1.6em;
    /* height: 1.6em; */
    display: block;
}

.maximize_button label {
    opacity: 0;
}

.unmaximize_button_wrapper {
    display: block;
    position: relative;
    left: 0.2em;
    width: 1em;
    height: 1em;
    top: 0.6em;
    margin-top: -1.6em;
    margin-left: -1em;
}

.unmaximize_button_wrapper label {
    opacity: 0;
}

.unmaximize_button_wrapper span {
    border: 1px solid #000;
    border-top: 2px solid #000;
    width: 1em;
    height: 1em;
    display:block;
}

.unmaximize_button_1 {
    position:absolute;
    top: 0.1em;
    left: 0.4em;
}

.unmaximize_button_2 {
    position:absolute;
    top: 0.65em;
    left: 0.1em;
}