@import "./assets/css-vars.scss";

.App {
  background-color: var(--background-color);
  height: 100%;
  width: 100%;
  position:absolute;
  top: 0;
  left: 0;
  font-family: "Pixel";

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently */
        
}

button {
  font-size: 0.56em !important;
  border-top: 2px solid var(--gray-highlight);
  border-left: 2px solid var(--gray-highlight);
  border-right: 2px solid var(--gray-dark);
  border-bottom: 2px solid var(--gray-dark);
  background-color: var(--gray-main);
  color: var(--gray-black);
  margin-bottom: 0.3em;
  width: 4.6rem;
  height: 1.5rem;
}

button:hover {
  border-top: 2px solid var(--gray-dark);
  border-left: 2px solid var(--gray-dark);
  border-right: 2px solid var(--gray-highlight);
  border-bottom: 2px solid var(--gray-highlight);
  cursor: pointer;
}

button:focus {
  outline: none;
}