
.psnSettings-wrapper {
    width: 90%;
    margin: 0 auto;
    position:relative;
    top: 5%;
    height: 90%;        
}

.psnSettings-wrapper .selector {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}