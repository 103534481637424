

.genMessage-wrapper {
    width: calc(100% - 2.2em);
    height: calc(100% - 2.2em);
    position: relative;
    top: 1em;
    left: 1em;
    font-size: 0.6em;
}

.message-wrapper {
    width: 100%;
    height: 5em;
    overflow: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2em;
    display: grid;
    justify-content: end;
}

.button-wrapper button {
    font-size: 0.8em !important;
}

.message-wrapper p {
    margin: 0;
    max-width: 70%;
}

.message-icon {
    width: 5em;
    // height: 2.5em;
    margin-right: 0.5em;

}