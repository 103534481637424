

.folderIconGrid-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(4em, 1fr));
    grid-gap: 0.5em;
    padding: 0.5em;
}

.folderIconGrid-icon {
    display: flex;
    align-items: center;
    padding-bottom: 0.1em;
}

.folderIconGrid-item {
    width: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0.2em;
    cursor: pointer;
}

.folderIconGrid-item-icon {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.5em;
    margin-top: 0.5em;
}

.folderIconGrid-item-icon img {
    width: 2em;
    height: 2em;
}

.folderIconGrid-item-label {
    font-size: 0.55em;
    text-align: center;
}


.folderIconGrid-item-icon img[src*="Desktop.ico"] {
    position:relative;
    top: -0.15em;
}