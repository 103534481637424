
.messenger-wrapper {
    width: calc(100% - 2em);
    height: calc(100% - 2em);
    position: relative;
    top: 1em;
    left: 1em;
}

.messenger-top {
    width: 100%;
    height: 6em;
    display:inline-flex;
}

.messenger-top-left {
    border: 1px solid var(--gray-dark);
    // double border
    border-left: 2px solid var(--gray-highlight);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    width: 75%;
}

.messenger-top-left label {
    font-size: 0.55em;
    font-weight: bold;
    margin-left: 1em;
    margin-top: 0.5em;
}

.messenger-top-left input[type="text"] {
    margin: 0;
    font-size: 0.66em;
    width: 90%;
    margin-bottom: 0.3em;
    border-top: 2px solid var(--gray-dark);
    border-left: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-highlight);
    border-bottom: 2px solid var(--gray-highlight);
}

.messenger-top-left input[type="text"]:focus {
    outline: none;
}

.messenger-top-left-recipients {
    display: flex;
    position:absolute;
    left: 0.5em;
}

.messenger-top-left-recipients input[type="radio"] {
    // set color when checked
    position:relative;
    top: 0.1em;
}

.messenger-top-left-body {
    position: relative;
}

.messenger-top-left-header {
 position:absolute;
 top: -1em;
 left: 0.4em;
 background-color: var(--gray-main);
}

.messenger-top-left-header label {
    margin: 0;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.messenger-top-inputs {
    position: absolute;
    top: 1.5em;
    left: 0.5em;
    display: grid;
    width: 100%;
}

.messenger-top-right {
    position:absolute;
    right: 0em;
    display: grid;
}

.messenger-top-right button {
    margin-bottom: 1em;
}

.messenger-bottom {
    position: absolute;
    top: 6em;
    width: 100%;
    height: calc(100% - 6em);
    display: flex;
    grid-template-columns: 1fr 1fr;
}


.messenger-message {
    width: 100%;
    height: 100%;
}

.messenger-message label {
    font-size: 0.6em;
    margin-left: 1em;
    margin-top: 0.5em;
    padding-bottom: 0.5em;
}

.messenger-message textarea {
    margin: 0;
    font-size: 0.66em;
    width: 100%;
    height: calc(100% - 2em);
    margin-bottom: 0.3em;
    border-top: 2px solid var(--gray-dark);
    border-left: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-highlight);
    border-bottom: 2px solid var(--gray-highlight);
    overflow: scroll;
    resize: none;
    padding: 0.5em;
}

.messenger-message textarea:focus {
    outline: none;
}
