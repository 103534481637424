@import "../../assets/css-vars.scss";

.desktop-wrapper {
    position:relative;
    width: 80%;
    height: 100%;
    margin: 0px auto;
}

.desktop-content-wrapper {
    position: relative;
    top: 10%;
    background-color: var(--desktop-green);
    height: 80%;
    width: 100%;
    border-radius: 0.3em;
}

@media only screen and (max-width: 768px) {
    .desktop-wrapper {
        position:relative;
        width: 100%;
        height: 100%;
        margin: 0px auto;
    }
    
    .desktop-content-wrapper {
        position: relative;
        top: 0em;
        background-color: var(--desktop-green);
        height: 100%;
        width: 100%;
        border-radius: 0em;
    }
}

@media only screen and (min-width: 1200px) {
    .desktop-wrapper {
        position:relative;
        width: 960px;
        height: 100%;
        margin: 0px auto;
    }
}

.exitStartMenu_listener {
    position:absolute;
    top: 0em;
    left: 0em;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.iconGrid {
    z-index: 30;
    position: relative;
}

.startMenu {
    z-index: 30;
}