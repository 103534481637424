@import "../../assets/css-vars.scss";

.icon-grid-wrapper {
    position:relative;
}


.icon-grid-left {
    position:absolute;
    left: 0em;
    top: 0em;
    margin-left: 1em;
}

.icon-grid-right {
    position:absolute;
    right: 0em;
    top: 0em;
    margin-right: 1em;
}

.icon-grid-wrapper td {
    width: 4em;
    padding-left: 0.25em;
    padding-right: 0.25em;
    height: 5em;
    z-index: -30;
}

.icon-grid-wrapper a {
    color: var(--text-default);
    text-decoration: none;
}

.icon-grid-wrapper a:hover {
    color: var(--text-default);
    text-decoration: none;
    cursor: pointer;
}

.icon-wrapper {
    width: 5em;  
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.icon-img-wrapper {
    // width: 3.5em;
    position:relative;
    display: grid;
    justify-content: center;
    align-items: center;
}

.icon-wrapper img {
    width: 2.7em;
    height: 2.7em;
    margin: 0px auto;
    align-items: center;
    display: flex;
}

.icon-wrapper label {
    font-size: 0.6em;
    width: 100%;
    // white-space: nowrap;
    overflow: hidden;
    text-align: center;
    padding-top: 0.5em;
    color: var(--text-white);
    // max 2 lines
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
}



// MINOR IMAGE ADJUSTMENTS


.icon-wrapper img[src*="my_computer.png"] {
    position:relative;
    left: 0em;
}

.icon-wrapper img[src*="Notepad_writing.ico"] {
    position:relative;
    // left: 0.1em;
}

.icon-wrapper img[src*="my_documents.ico"] {
    position:relative;
    left: -0.1em;
    // zoom in on image
    transform: scale(1.3);
}


.icon-wrapper img[src*="trash_full.ico"] {
    position:relative;
    left: 0em;
}