
.folderIconList-wrapper ul {
    padding-left: 0;
    margin: 0;
}

.folderIconList-wrapper ul li {
    list-style-type: none;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 0.6em;
}

.folderIconList-item {
    display: flex;
    align-items: center;
    padding-bottom: 0.1em;
}

.folderIconList-item-icon img {
    width: 1.6em;
    height: 1.6em;
    margin-right: 0.5em;
}



