

.run-wrapper {
    width: 90%;
    margin: 0 auto;
    position:relative;
    top: 5%;
    height: 90%;        
}

.run-wrapper .run-top {
    width: 100%;
    height: 20%;
    position: absolute;
    display:inline-flex;
    justify-content: center;
}

.run-wrapper .run-top-left {
    width: 4em;
}

.run-wrapper .run-top-left img {
    width: 2.5em;
    height: auto;
}

.run-wrapper .run-top p {
    font-size: 0.56em;
}

.run-wrapper .run-middle {
    width: 100%;
    height: 60%;
    position: absolute;
    top: 20%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.run-wrapper .run-middle-left {
    width: 45%;
    margin-right: 0.5em;
}

.run-wrapper .run-middle-right {
    width: 45%;
    margin-left: 0.5em;
}

.run-wrapper .run-middle p {
    font-size: 0.5em;
    margin: 0;
}

.run-wrapper .run-middle input {
    font-size: 0.66em;
    width: 13em;
    height: 2em;
    position:relative;
    top: -0.2em;
    border-top: 2px solid var(--gray-dark);
    border-left: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-highlight);
    border-bottom: 2px solid var(--gray-highlight);
    appearance: none;
}

.run-wrapper .run-middle input:focus {
    outline: none;
}


.run-wrapper .run-bottom {
    width: 100%;
    height: 20%;
    position: absolute;
    top: 80%;
    display: inline-flex;
    justify-content: end;
}


.run-wrapper .run-bottom button {
    font-size: 0.5em;
    border-top: 2px solid var(--gray-highlight);
    border-left: 2px solid var(--gray-highlight);
    border-right: 2px solid var(--gray-dark);
    border-bottom: 2px solid var(--gray-dark);
    background-color: var(--gray-main);
    color: var(--gray-black);
    margin-bottom: 0.3em;
    width: 4.6rem;
    height: 1.5rem;
    margin-left: 0.5em;
}

.run-wrapper .run-bottom button:hover {
    border-top: 2px solid var(--gray-dark);
    border-left: 2px solid var(--gray-dark);
    border-right: 2px solid var(--gray-highlight);
    border-bottom: 2px solid var(--gray-highlight);
    cursor: pointer;
}

.run-wrapper .run-bottom button:focus {
    outline: none;
}