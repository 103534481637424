


.login-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

//mobile 
@media (max-width: 768px) {
    .login-wrapper {
        width: 100%;
        height: 100%;
    }
}

.login-background-wrapper {
    position:absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.login-background-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--desktop-green);
}

.login-page-content {
    position:absolute;
    z-index: 30;
}