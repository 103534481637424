
.windowCordinator-wrapper {
    position:absolute;
    top: 0em;
    left: 0em;
    width: 100%;
    height: 100%;
}

.windowZPlacement {
    position: relative;
}