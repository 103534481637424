
.settings-wrapper {
    width: 90%;
    margin: 0 auto;
    position:relative;
    top: 5%;
    height: 90%;        
}

.settings-top {
    width: 100%;
    height: 20%;
    position: absolute;
    display:inline-flex;
    justify-content: center;
}

.settings-top h1 {
    font-size: 0.66em;
}