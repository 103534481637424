
:root {
    --background-color: #000;

    // Windows 95 Colors Scheme
    --desktop-green: #55AAAA;
    // --desktop-green: #2E8282;
    --blue95: #2400AA;

    // grays
    // --gray-main: #C3C7CB;
    --gray-main: #D4D0C8;
    --gray-light: #fff;
    --gray-highlight: #FDFDFD;
    --gray-dark: #868A8E;
    --gray-black: #000;

    // text color
    --text-default: #000;
    --text-white: #fff;
    --text-black: #000;
}

[data-theme="dark"] {
    --background-color: #000;
}


[data-theme="light"] {
    --background-color: rgb(255, 252, 227);
}

@font-face {
    font-family: "Pixel";
    src: url("../assets/fonts/windows/w-95-sans-serif.woff");
    font-weight: normal;
    font-style: normal;
}